import { useState } from 'react';
import useInterval from './useInterval';

export default function useTimer(countDown = 60) {
    const [timer, setTimer] = useState(0);
    const [started, setStarted] = useState(false);

    const start = () => {
        setTimer(countDown);
        setStarted(true);
    };

    const stop = () => {
        setTimer(0);
        setStarted(false);
    };

    const processCountDown = () => {
        setTimer(timer - 1);
        if (timer - 1 <= 0) {
            stop();
        }
    };

    useInterval(processCountDown, started ? 1000 : null);

    return { timer, start, stop };
}
