import { useEffect, useState, useMemo } from 'react';
import BREAKPOINT from 'src/shared/config/Breakpoint';

const getDeviceConfig = (width) => {
    if (width <= BREAKPOINT.XXS) {
        return 'xxs';
    }
    if (width <= BREAKPOINT.XS) {
        return 'xs';
    }
    if (width <= BREAKPOINT.SM) {
        return 'sm';
    }
    if (width <= BREAKPOINT.MD) {
        return 'md';
    }
    if (width <= BREAKPOINT.LG) {
        return 'lg';
    }
    if (width <= BREAKPOINT.XL) {
        return 'xl';
    }
    if (width <= BREAKPOINT.XXL) {
        return 'xxl';
    }
    return '';
};

const useResize = () => {
    const [breakpoint, setBreakpoint] = useState(null);
    const [screenSize, setScreenSize] = useState({ width: null, height: null });

    useEffect(() => {
        let raf = null;
        const calcInnerWidth = () => {
            if (raf) {
                return;
            }
            raf = requestAnimationFrame(() => {
                raf = null;
                const b = getDeviceConfig(window.innerWidth);
                if (breakpoint !== b) {
                    setBreakpoint(b);
                }
                if (screenSize.width !== window.innerWidth || screenSize.height !== window.innerHeight) {
                    setScreenSize({
                        width: window.innerWidth,
                        height: window.innerHeight,
                    });
                }
            });
        };
        window.addEventListener('resize', calcInnerWidth);
        calcInnerWidth();
        return () => window.removeEventListener('resize', calcInnerWidth);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        breakpoint,
        screenSize,
        isDesktop: useMemo(() => ['xl', 'xxl', ''].indexOf(breakpoint) !== -1, [breakpoint]),
        isTablet: useMemo(() => ['lg'].indexOf(breakpoint) !== -1, [breakpoint]),
        isMobile: useMemo(() => ['xxs', 'xs', 'sm', 'md'].indexOf(breakpoint) !== -1, [breakpoint]),
        isSmallMobile: useMemo(() => ['xxs'].indexOf(breakpoint) !== -1, [breakpoint]),
    };
};

export default useResize;
