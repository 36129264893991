import { useCallback } from 'react';
import { useQuery as useReactQuery, UseQueryOptions } from 'react-query';
import { useDispatch } from 'react-redux';
import { globalActions } from 'src/shared/store/global';
import ErrorService from '../services/ErrorService';
import GetApiFunction from '../types/api/APIFunction';
import useMemberInfo from 'src/shared/hooks/useMemberInfo';

interface QueryOptions<T extends any> extends UseQueryOptions {
    customQueryKey?: any;
    params: T;
}

function useApiQuery<Params extends any = any, Result extends any = any>(fn: GetApiFunction<Params>, options?: QueryOptions<Params>) {
    const { token } = useMemberInfo();
    const dispatch = useDispatch();
    const { params, customQueryKey, onError, ...originalOptions } = options || {};

    const handleError = useCallback(
        (err) => {
            const error = ErrorService.getAPIError(err);
            if (onError) {
                onError(error);
            } else {
                dispatch(globalActions.showErrorDialog(error));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onError]
    );

    const query = useReactQuery<any, any, Result, any>([fn.key, JSON.stringify(customQueryKey || params)], () => fn(token, params), {
        ...originalOptions,
        onError: handleError,
    } as any);

    return query;
}

export default useApiQuery;
