import { useEffect } from 'react';
import { UseMutationResult, UseQueryResult } from 'react-query';
import { useDispatch } from 'react-redux';
import ErrorService from 'src/shared/services/ErrorService';
import { globalActions } from 'src/shared/store/global';

function useQueryAndMutationErrors(...queries: Array<UseQueryResult<any> | UseMutationResult<any>>) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (queries.some((x) => x.isError)) {
            const error = ErrorService.getAPIError(queries.filter((x) => x.isError).find((x) => x.error));
            dispatch(globalActions.showErrorDialog(error));
        }
    }, [...queries.map((x) => x.isError)]);
}

export default useQueryAndMutationErrors;
