import { useCallback } from 'react';
import { useMutation as useReactMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';
import { globalActions } from 'src/shared/store/global';
import { AlertDialogDTO } from 'src/shared/store/global/types';
import ErrorService from '../services/ErrorService';
import ApiFunction from '../types/api/APIFunction';
import useMemberInfo from 'src/shared/hooks/useMemberInfo';

interface MutationOptions<T extends any> extends UseMutationOptions {
    customMutationKey?: any;
    onError?: (error: any) => void;
    onSuccess?: () => void;
    successDialog?: AlertDialogDTO;
}

function useApiMutation<Params extends any = any, Result extends any = any>(fn: ApiFunction<Params>, options: MutationOptions<Params>) {
    const { token } = useMemberInfo();
    const dispatch = useDispatch();
    const { customMutationKey, successDialog, onError, onSuccess, ...originalOptions } = options;

    const handleError = useCallback(
        (err) => {
            const error = ErrorService.getAPIError(err);
            if (onError) {
                onError(error);
            } else {
                dispatch(globalActions.showErrorDialog(error));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onError]
    );

    const handleSuccess = useCallback(() => {
        if (onSuccess) {
            onSuccess();
        }
        if (successDialog) {
            dispatch(
                globalActions.showAlertDialog({
                    ...successDialog,
                    title: successDialog?.title || 'Save Successfully',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSuccess]);

    const config: any = {
        ...originalOptions,
        onError: handleError,
        mutationKey: [fn.key, JSON.stringify(customMutationKey || {})],
        ...((onSuccess || successDialog) && {
            onSuccess: handleSuccess,
        }),
    };

    const mutation = useReactMutation<any, any, Result, any>((params: any) => fn(token, params), config);
    return {
        ...mutation,
        mutateAsync: (asyncVariables?: any) =>
            // Original mutateAsync will duplicate onError logic
            new Promise<any>((resolve, reject) => {
                mutation.mutate(asyncVariables, {
                    onSuccess: resolve,
                    onError: reject,
                });
            }),
    };
}

export default useApiMutation;
