import { useTranslation } from 'next-i18next';
import GaAction from 'src/config/GaAction';

const generatePhoneHref = (phone: string) => `tel:${phone.replace(/ /g, '')}`;

const useCompanyInfo = () => {
    const { t } = useTranslation();

    const email = 'info@altive.com';

    const emailHref = `mailto:${email}`;

    const csEmail = 'cs@altive.com';

    const csEmailHref = `mailto:${csEmail}`;

    const phone = {
        hk: '+852 3792 0299',
        au: '+61 422 610 660',
    };

    const phoneHref = {
        hk: generatePhoneHref(phone.hk),
        au: generatePhoneHref(phone.au),
    };

    const location = {
        hk: {
            address: t('portal_contact_info_address_details_hk'),
            addressLink: 'https://goo.gl/maps/nNMgDevruY5ZWZV77',
        },
        // au: {
        //     address: t('portal_contact_info_address_details_au'),
        //     addressLink:
        //         'https://www.google.com/maps/place/Australia+Square/@-33.8650928,151.2072126,19z/data=!3m1!4b1!4m5!3m4!1s0x6b12af9f02b68263:0x87912fc3fdec385d!8m2!3d-33.865137!4d151.2077539',
        // },
    };

    const whatsappHref = 'https://api.whatsapp.com/send?phone=85261122492';

    const socialMedia = {
        linkedIn: {
            name: 'LinkedIn',
            icon: 'btn_footer_linkedin',
            href: 'https://www.linkedin.com/company/altive/',
            gaAction: GaAction.clickLinkedIn,
            shouldTriggerDownload: false,
        },
        email: {
            name: 'e-mail',
            icon: 'btn_footer_email',
            href: emailHref,
            gaAction: GaAction.clickEmail,
            shouldTriggerDownload: false,
        },
        whatsApp: {
            name: 'WhatsApp',
            icon: 'btn_footer_whatsapp',
            href: whatsappHref,
            gaAction: GaAction.clickWhatsApp,
            shouldTriggerDownload: false,
        },
        weChat: {
            name: 'WeChat',
            icon: 'btn_footer_wechat',
            href: '/static/images/weChatQRcode.jpg',
            gaAction: GaAction.clickWeChat,
            shouldTriggerDownload: true,
        },
    };

    const connects = [socialMedia.linkedIn, socialMedia.email, socialMedia.whatsApp, socialMedia.weChat];

    const getParamByLocale = (locale, paramDef) => {
        switch (locale) {
            case 'au':
                return paramDef.au;
            default:
                return paramDef.hk;
        }
    };

    const getPhone = (locale) => getParamByLocale(locale, phone);

    const getPhoneHref = (locale) => getParamByLocale(locale, phoneHref);

    const getLocation = (locale) => getParamByLocale(locale, location);

    return {
        email,
        emailHref,
        csEmail,
        csEmailHref,
        phone,
        phoneHref,
        location,
        socialMedia,
        connects,
        getPhone,
        getPhoneHref,
        getLocation,
    };
};

export default useCompanyInfo;
