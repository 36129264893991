const BREAKPOINT = {
    XXS: 360,
    XS: 480,
    SM: 576,
    PHONE: 768,
    MD: 768,
    LG: 1024,
    TABLET: 1024,
    XL: 1440,
    SMALL_SCREEN: 1440,
    XXL: 2500,
};

export default BREAKPOINT;
